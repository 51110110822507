<template>
  <section class="montserrat-regular">
    <div class="col-lg-9 second-section-text py-5 container">
      <div class="heading text-center">
        <h2>{{ information.title }}</h2>
        <h5 class="color-gray mt-3">
          {{ information.text }}
        </h5>
      </div>
    </div>
    <Banner :banner="information" />
  </section>
</template>

<script>
import Banner from "@/components/Banner.vue";

export default {
  name: "SecondSection",
  components: { Banner },
  props: ["information"],
  data() {
    return {};
  },
  methods: {},
  computed: {},
};
</script>
