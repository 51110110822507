<template>
  <div class="ourProducts">
    <MainBanner class="d-none d-md-flex" :title="mainTitle" />
    <img class="w-100 montserrat-regular d-md-none" :src="images.mainMobile" />
    <SecondSection :information="secondSection" />
    <Products id="seguroPatrimonial" :product="productSectionOne">
      <template v-slot:content>
        <div class="row">
          <ul class="col-md-6 col-sm-12">
            <li>Automotores</li>
            <li>Caución</li>
            <li>Comercios</li>
            <li>Consorcios</li>
            <li>Hogar</li>
            <li>Incendio</li>
            <li>Responsabilidad Civil</li>
            <li>Todo Riesgo Construcción</li>
          </ul>
          <ul class="col-md-6 col-sm-12">
            <li>Todo Riesgo Operativo</li>
            <li>Accidentes Personales</li>
            <li>Riesgos Industriales</li>
            <li>Caución ambiental</li>
            <li>Seguro Técnico</li>
            <li>Trasporte de Mercaderías</li>
            <li>Mala praxis</li>
          </ul>
        </div>
      </template>
    </Products>
    <Products id="retiroInversion" :product="productSectionTwo">
      <template v-slot:content>
        <div class="row">
          <ul class="col-12">
            <li>Protección familiar</li>
            <li>Seguros individuales y colectivos</li>
            <li>Responsabilidad profesional</li>
            <li>Instrumentos de renta</li>
            <li>Planificación financiera</li>
            <li>Productos de inversión según segementos</li>
          </ul>
        </div>
      </template>
    </Products>
    <Products id="riesgoTrabajo" :product="productSectionThree">
      <template v-slot:content>
        <div class="mb-5">
          <p class="row" style="font-size: 1rem">
            Protegé tu trabajo. Analizamos riesgos y diseñamos el mejor programa
            de seguros para vos y tu empresa.
          </p>
          <ul class="col-12">
            <li>ART</li>
            <li>Accidentes personales</li>
          </ul>
        </div>
      </template>
    </Products>
  </div>
</template>

<script>
import MainBanner from "@/components/MainBanner.vue";
import SecondSection from "@/components/SecondSection.vue";
import Products from "@/components/Products.vue";

import mainBannerImage from "@/assets/main-banner-nuestro-producto-01.png";
import bannerImage from "@/assets/second-banner-nuestro-producto-01.png";
import productOne from "@/assets/card-fortaleza-one-01.png";
import productTwo from "@/assets/card-fortaleza-two-01.png";
import productThree from "@/assets/card-fortaleza-three-01.png";
import mainMobile from "@/assets/mobile/nuestros-prod-m.png";

export default {
  name: "OurProducts",
  components: {
    MainBanner,
    SecondSection,
    Products,
  },
  data() {
    return {
      images: {
        mainMobile,
      },
      mainTitle: {
        first: "NUESTROS",
        second: "RITUALES",
        subtext: "Escucharte, asesorarte y cuidarte",
        img: mainBannerImage,
        bgColor: "var(--dark-blue)",
      },
      secondSection: {
        goto: "productores",
        gotoId: "#vitaeForm",
        title: "TENEMOS TRAYECTORIA Y FORTALEZA EN SEGUROS.",
        text: "Contamos con distintas opciones para adaptarnos a lo que estás buscando.",
        centerWord: "FORTALEZA",
        centerWordTwo: "EN SEGUROS",
        bannerTitleOne: "VIDA",
        bannerTitleSecond: "PROTEGIDA",
        bannerText:
          "Que la vida se convierta en un ritual todos los días: único, irrepetible, con ese valor especial de cuidar, cuidarte, estar seguro y sentirte bien.",
        img: bannerImage,
        bgColor: "var(--light-orange)",
      },
      productSectionOne: {
        title: "CONTRATÁ TU SEGURO",
        subtitle: "PATRIMONIALES",
        img: productOne,
        bgColor: "var(--tertiary-blue)",
      },
      productSectionTwo: {
        title: "PLANIFICÁ Y PROTEGÉ A TU FAMILIA",
        subtitle: "VIDA, RETIRO E INVERSIÓN",
        img: productTwo,
        bgColor: "var(--light-orange)",
      },
      productSectionThree: {
        title: "ASEGURATE EN TU TRABAJO",
        subtitle: "RIESGOS DE TRABAJO",
        img: productThree,
        bgColor: "var(--secondary-blue)",
      },
    };
  },
};
</script>
