<template>
  <section
    class="main-banner montserrat-regular"
    :style="{ backgroundImage: 'url(' + title.img + ')' }"
  >
    <p class="main-title">
      <span :style="{ background: title.bgColor }">
        <b>
          <ins>{{ title.first }}</ins>
        </b>
      </span>
      <br />
      <span :style="{ background: title.bgColor }">
        {{ title.second }}
      </span>
    </p>
    <span class="subtext d-none d-lg-block" v-if="title.subtext">
      {{ title.subtext }}
    </span>
    <!-- <a
      href="https://wa.me/5491169761773"
      target="_blank"
      color="#4dc247"
      class="whatsapp"
    >
      <img width="50" src="/img/whatsapp-logo.png" alt="" />
    </a> -->
  </section>
</template>

<script>
export default {
  name: "MainBanner",
  components: {},
  props: ["title"],
  data() {
    return {};
  },
  methods: {},
  computed: {},
};
</script>
