<template>
  <section class="montserrat-regular col-lg-9 m-auto">
    <div class="heading pt-4 text-center">
      <h2 class="py-1" :style="{ background: product.bgColor }">
        <span> {{ product.title }} </span>
      </h2>
    </div>
    <div class="row m-4">
      <!-- <div style="width: 80%, height: 80%"> -->
      <div
        class="col-md-6 products_img"
        :style="{ backgroundImage: 'url(' + product.img + ')' }"
      />
      <!-- </div> -->
      <div class="col-md-6 products_items">
        <h7 class="row justify-content-left padding-left">
          {{ product.subtitle }}
        </h7>
        <slot name="content"></slot>
        <div class="row justify-content-end align-items-end">
          <button
            class="p-1 px-3"
            style="border: none"
            :style="{ background: product.bgColor }"
          >
            <router-link data-goto="#contacto" to="contactos" class="text-white goto">
              COTIZÁ TU SEGURO
            </router-link>
          </button>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "Products",
  components: {},
  props: ["product"],
  data() {
    return {};
  },
};
</script>
