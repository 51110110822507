<template>
  <div
    class="banner montserrat-regular"
    style="min-height: 300px"
    :style="{ backgroundImage: 'url(' + banner.img + ')' }"
  >
    <div class="position-absolute" style="width: 100%">
      <p
        class="col-6"
        :class="
          banner.centerWordTwo ? 'banner-center-word-two' : 'banner-center-word'
        "
      >
        {{ banner.centerWord }} <br />
        {{ banner.centerWordTwo }}
      </p>
    </div>
    <div class="banner-cotainer-text">
      <span :style="{ background: banner.bgColor }">
        {{ banner.bannerTitleOne }}
      </span>
      <br />
      <span :style="{ background: banner.bgColor }">
        {{ banner.bannerTitleSecond }}
      </span>
      <p class="p-color">
        {{ banner.bannerText }}
      </p>
      <div class="row w-75 justify-content-end">
        <router-link class="goto" :to="banner.goto" :data-goto="banner.gotoId">
          <button v-if="banner.btn" type="button" class="text-white banner-btn">
            {{ banner.btn }}
          </button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SecondSection",
  components: {},
  props: ["banner"],
  data() {
    return {};
  },
  methods: {},
  computed: {},
};
</script>
